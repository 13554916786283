@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');

html, body {
    color: #000000;
    font-size: 16px;
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Lato", "Roboto", "Helvetica Neue", sans-serif;
    font-weight: 400;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

code {
    color: inherit;
    background-color: #eeeeee;

    &.multiline {
        white-space: pre;
    }

    &.copyable:hover {
        color: inherit;
        background-color: #dddddd;
        cursor: copy;
    }
}

/* Main */
#main {

    h1 {
        font-weight: bold;
        color: #00287a;
    }
}

/* Navbar */
.navbar-text {
    line-height: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

ul.navbar-right .navbar-text {
    margin-left: 1rem;
}

.navbar-header {
    float: left;
}

.navbar-nav {
    margin: 0;

    li {
        float: left;
    }
}
.dropdown-menu {
    li {
        float: none;
    }
}

.navbar-right {
    float: right !important;
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
}

.navbar-category {
    margin-left: 7px;
    margin-top: 0;
    margin-bottom: 0;
}

ul.navbar-nav li {
    white-space: nowrap;
}

.navbar-btn .btn {
    margin-left: 1rem;
    margin-right: 1rem;
    min-height: 2rem;
    line-height: 2rem;
    font-size: .75rem;
}

.brand.dropdown .dropdown-menu a {
    padding: 8px 0;
    color: #000;
}

.current-product-logo {
    width: 40px;
    height: 40px;
    margin: -17px 0 -15px -15px;
}

.dropdown-menu .glyphicon {
    margin-right: 1rem;
    font-size: 16px;
    transform: translate(0, 1px);
    line-height: 0;
}

.product-chooser-logo {
    width: 30px;
    height: 30px;
}

@media only print {
    table.dataTable thead .sorting:after,
    table.dataTable thead .sorting_asc:after,
    table.dataTable thead .sorting_desc:after,
    table.dataTable thead .sorting_asc_disabled:after,
    table.dataTable thead .sorting_desc_disabled:after {
        display: none;
        left: 0;
    }

    table.dataTable thead > tr > th.sorting_asc,
    table.dataTable thead > tr > th.sorting_desc,
    table.dataTable thead > tr > th.sorting,
    table.dataTable thead > tr > td.sorting_asc,
    table.dataTable thead > tr > td.sorting_desc,
    table.dataTable thead > tr > td.sorting {
        padding-left: 8px !important;
    }

    .dataTables_wrapper .row:first-child,
    .dataTables_wrapper .row:last-child {
        display: none;
    }

    .progress span {
        text-align: left;
    }

    a[href]:after {
        content: none !important;
    }

    iframe {
        display: none;
    }

    /* Don't display hubspot chatbot in print */
    #hubspot-messages-iframe-container {
        position: absolute !important;
        left: -9999px !important;
        height: 0 !important;
        padding: 0 !important;
        overflow: hidden !important;
    }
}

.main-content-limiter {
    max-width: 1440px;
}

/* Icons */
.glyphicon {
    user-select: none;
    margin-right: 8px;
}

.glyphicon-user {
    font-size: 15px;
    transform: translate(0, 1px);
}

.glyphicon.single {
    margin: 0;
}

.btn .glyphicon {
    font-size: 12px;
}

.btn.btn-xs .glyphicon {
    font-size: 10px;
}

/* Jumbotrons */
.jumbotron {
    color: #000000;
}

/* List groups */
.list-group {
    color: #000000;
}

/* Tables */
table.table {
    background-color: #ffffff;
    color: #000000;
    /*border-collapse: separate;*/

    .table-nested {
        width: 1px;
        background-color: transparent;
        white-space: nowrap;
    }

    td {
        vertical-align: middle;
    }

    th {
        white-space: nowrap;
    }

    tr {
        vertical-align: middle;
    }

    ul {
        margin-bottom: 0;
        line-height: 1.5rem;
    }

    > tbody > tr > td.column-tight {
        padding: 0;
    }
}

table.table-truncate {
    table-layout: fixed;

    td {
        max-width: 0;
    }
}

table.table-horizontal th:first-child {
    width: 16.66666667%;
}

table.table-inline th,
table.table-inline td {
    padding-right: 1em;
}

table.table-inline th:last-child,
table.table-inline td:last-child {
    padding-right: 0;
}

.table > thead > tr > th {
    border-bottom: 1px solid #c5c5c5;
}

.table > tbody > tr > th,
.table > tbody > tr > td {
    border-top: 1px solid #e3e3e3;
    padding: 4px 8px;
    line-height: 2rem;
}

.table > tbody > tr > th .btn-xs,
.table > tbody > tr > td .btn-xs,
.table > tbody > tr > td .btn-s {
    margin-top: -4px;
}

.column-compact {
    width: 1px;
    white-space: nowrap;
}

.column-hidden {
    display: none;
}

.column-left {
    text-align: left;
    white-space: nowrap;
}

.column-right {
    text-align: right;
    white-space: nowrap;
}

.empty-row {
    display: none;
}

.dragging .empty .empty-row {
    display: table-row;
    color: #cccccc;
    text-align: center;
}

.row-move-handler {
    padding: 8px;
    color: #cccccc;
    cursor: ns-resize;
}

.vertical-baseline {
    vertical-align: baseline;
}

/*
.table-striped > tbody > tr:nth-child(2n+1) > th,
.table-striped > tbody > tr:nth-child(2n+1) > td {
    background-color: #f7f8f9;
}

.table-hover > tbody > tr:hover > th,
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:nth-child(2n+1) > th:hover,
.table-hover > tbody > tr:nth-child(2n+1) > td:hover {
    background-color: #e9edf1;
}
*/

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fff;
}

.table-striped > tbody > tr:nth-child(2n+1) > th, .table-striped > tbody > tr:nth-child(2n+1) > td {
    background-color: transparent;
}

.table-hover > tbody > tr:hover {
    background-color: #f7f7f7;
}

/* Widgets */
.btn.opaque {
    opacity: 1;
}

.btn.pull-right + .btn.pull-right {
    margin-right: 4px;
}

.form-intro {
    margin-bottom: 2em;
}

.group-status {
    margin-bottom: 0;
}

.color-box {
    float: left;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: 1px solid #000000;
}

.help-label {
    cursor: help;
}

.image-checkerboard {
    background-color: #eeeeee;
    background-image: linear-gradient(45deg, #000000 25%, transparent 25%, transparent 75%, #000000 75%, #000000), linear-gradient(45deg, #000000 25%, transparent 25%, transparent 75%, #000000 75%, #000000);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
}

.form-group .iti {
    display: block;
}

.ballot-x {
    color: #d9534f;
}

.ballot-x:before {
    content: "✗";
}

.check-mark {
    color: #5cb85c;
}

.check-mark:before {
    content: "✓";
}

.map-view-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #000000;
    opacity: 0.5;
}

.map-view-frame {
    position: fixed;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    z-index: 1000;
    border: 1px solid #000000;
    box-shadow: 5px 5px 10px #000000;
}

.message-preview {
    height: auto;
    background-color: #eeeeee;
    white-space: pre;
}

.preview-label {
    vertical-align: super;
    color: #800080;
}

.text-bold {
    font-weight: bold;
}

/* Adjust svg icons */
td.svg-icon svg {
    display: table-cell;
    width: 30px;
    height: 30px;
}

/* Fix "padding" without an external package */
.left-pad {
    margin-left: 8px;
}

.right-pad {
    margin-right: 8px;
}

/* Fix Bootstrap form margins */
.form-group .help-block,
.form-group .table {
    margin-bottom: 0;
}

.form-col {
    margin-bottom: 8px;
}

/* Fix Bootstrap form label font weight */
label {
    font-weight: normal;
}

label.required {
    font-weight: bold;
}

/* Add asterisk after required labels */
label.required:after {
    content: " *";
    color: #f51e55;
    line-height: 16px;
    font-size: 18px;
}

/* Fix Bootstrap label font weight */
.label {
    font-weight: normal;
}

/* Fix Bootstrap label color in panels */
.panel-default {
    border-color: #dde0e4;
}

.panel-default > .panel-heading {
    background-color: #ffffff;
    border-color: #e3e3e3;
    min-height: 43px;
}

.panel-default .panel-footer {
    background-color: #ffffff;
    border-color: #e3e3e3;
}

.panel-title {
    font-size: 20px;
}

.panel .table-responsive {
    overflow-x: unset;
}

.panel .table > tbody > tr > th {
    font-weight: 600;
}

/* Fix Bootstrap menu styles */
.navbar-default {
    background-color: #ffffff;
    border-color: #e3e3e3;
    min-height: 48px;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-width: 0 0 1px;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    background-color: inherit;
    font-weight: bold;
}

.dropdown-menu > .active.open > a,
.dropdown-menu > .active.open > a:hover,
.dropdown-menu > .active.open > a:focus,
.navbar-default .navbar-nav > .active.open > a,
.navbar-default .navbar-nav > .active.open > a:hover,
.navbar-default .navbar-nav > .active.open > a:focus {
    background-color: #f7f7f7;
}

.dropdown-menu > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #000;
}

.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #000;
    background-color: #f7f7f7;
}

.navbar-default .navbar-nav > .active > a {
    color: #000;
}

/* Fix Bootstrap pulling the last child of .navbar-right to the right */
.navbar-nav.navbar-right:last-child {
    margin-right: 0.5rem;
}

/* Hide empty Bootstrap headers and footers */
.panel-heading:empty,
.panel-footer:empty {
    display: none;
}

/* Add plain Bootstrap readonly style */
.form-control[readonly].readonly {
    cursor: inherit;
    background-color: inherit;
}

/* DataTables adjustments */
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    left: 8px;
    right: unset;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
    padding-right: 0.5rem;
    padding-left: 28px;
    line-height: 1.5rem;
}

.dataTables_filter,
.dataTables_info,
.dataTables_length,
.dataTables_paginate .pagination {
    margin: 0.5rem 0 !important;
}

.dataTables_filter input {
    margin-left: 1ex;
}

.dataTables_wrapper .row.bottom {
    display: none;
}

.dataTables_wrapper.non-empty .row.bottom {
    display: block;
}

table.data-table--server-side {
    /* Relative to make spinner display correctly */
    position: relative;
}

table.data-table--server-side > tbody.initial {
    /* Relative because we absolutely position content inside */
    position: relative;
    height: 400px;
    background-color: #ffffff;
}

table.data-table--server-side.loading.non-empty > tbody > tr {
    /* Disable interaction with existing data during loading */
    pointer-events: none;
    /* And make it clear that the data can't be interacted with */
    opacity: 0.25;
}

table.data-table--foldout .dataTables_empty,
table.data-table--server-side .dataTables_empty {
    height: 50px;
    vertical-align: middle;
    text-align: center;
}

table.data-table--server-side.empty > thead > tr > th {
    /* Disable sorting if table is empty */
    pointer-events: none;
}

table.data-table--server-side .spinner {
    /* Fix spinner broken by data cards */
    display: block;
    opacity: 1;
}

table.data-table--server-side .jumbotron {
    /* Position jumbotrons inside table correctly */
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: none;
    text-align: center;
}

table.data-table--server-side .jumbotron-markdown div {
    margin-bottom: 40px;
}

table.data-table--server-side .jumbotron-markdown h3 {
    margin-top: 0;
}

/* Date and time picker adjustments */
.date-picker-group .date-picker,
.time-picker-group .time-picker,
.datetime-picker-group.date .datetime-date-picker,
.datetime-picker-group.datetime .datetime-time-picker {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

/* Ladda adjustments */
.ladda-button[data-style=expand-left][data-loading][data-size="xs"] {
    padding-left: 32px;
}

.ladda-button[data-style=expand-left][data-size="xs"] .ladda-spinner {
    left: 16px;
}

.ladda-button[data-style=expand-right][data-loading][data-size="xs"] {
    padding-right: 24px;
}

.ladda-button[data-style=expand-right][data-size="xs"] .ladda-spinner {
    right: -20px;
}

/* Select2 adjustments */
img.select2-icon,
img.select2-icon {
    width: 15px;
    height: 15px;
    margin-top: -3px;
    margin-right: 5px;
    vertical-align: middle;
}

img.image-checkerboard {
    /* Override checkerboard background */
    background: none;
}

.select2-results__group {
    padding: 3px 7px 4px;
    margin: 0;
    color: black;
    font-size: 14px;
}

.select2-container-disabled.form-control {
    background-color: #eeeeee;
}

.select2-default {
    color: #777777 !important;
}

.select2-hide-disabled .select2-disabled {
    display: none;
}

.select2-placeholder {
    color: #999999;
}

.select2-results__option[aria-disabled="true"], .select2-disabled {
    background-color: #eeeeee;
    color: #777777;
}

.select2-results__option[aria-selected="true"] {
    display: none !important;
}

.select2-results .select2-disabled .select2-result-label, .select2-results__group {
    cursor: not-allowed;
}

.select2-search__field,
.select2-results__option {
    color: #555555;
}

/* Spectrum adjustments */
div.sp-replacer,
input.sp-input {
    background-color: #ffffff;
}

.product-subscriptions table tr td {
    line-height: 32px;
    width: 25%;
}

.product-subscriptions img {
    width: 32px !important;
    height: 32px !important;
}

.billing-terms table tr td {
    width: 50%;
}

/* Bootstrap overrides for equal height columns css3 only */
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 32px;
}

.close--tight {
    margin-top: -10px;
    margin-right: -5px;
}

.data-table--foldout:hover {
    cursor: pointer;
}

.data-table--foldout tr.shown + tr {
    background-color: transparent !important;
}

.data-table--foldout tr.shown + tr:hover {
    background-color: transparent !important;
}

.data-table--foldout tr.shown + tr:hover {
    cursor: default;
}

.receipt {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.receipt .header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    width: 100%;
}

.receipt table {
    width: 60%;
    margin: 0 auto;
}

.receipt table thead th {
    font-weight: bold;
    font-size: 14px !important;
}

.receipt table thead th:first-of-type {
    width: 80%;
    text-align: left;
    margin: 10px;
}

.receipt table thead th {
    text-align: center;
    border-bottom: 1px solid #dadada;
    font-size: 16px;
    padding-bottom: 3px;
}

.receipt table tbody tr td {
    padding: 5px;
    margin: 0 10px 0 10px;
}

.receipt table tbody tr:hover {
    background-color: #f1f1f1;
}

.receipt table tbody tr td:first-of-type {
    width: 50%;
    text-align: left;
    padding-left: 17px;
}

.receipt table tbody tr td {
    text-align: center;
}

.receipt table tbody tr:last-of-type td:first-of-type {
    border-bottom: 0;
}

/* SVG icons */
.svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.svg-centered {
    text-align: center;
}

/* Utilities */
.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* Admin Center */
.settings-panel {
    padding-top: 0;
}

.settings-panel-row {
    border-bottom: 1px solid grey;
}

.buttons {
    margin-top: 40px;
}

/* Admin Center */
.settings-panel {
    padding-top: 0;
}

.settings-panel-row {
    border-bottom: 1px solid grey;
}

.settings-panel h3 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.divider-label {
    margin-bottom: 0;
    text-align: left;
}

.transitionless {
    transition: none !important;
}

/* ----- Empty panel heading ----- */
.panel-heading.panel-heading-empty {
    display: block;
    border: none;
}

/* ----- Toggle advanced settings ----- */
/* autoprefixer: last 1 version, > 1%, not dead, not op_mini all */
/* Toggle */
.advanced-settings-toggle-container {
    position: relative;
    padding: 15px 0;
}

span.caret.advanced-settings-toggle-icon {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translate3d(0, -50%, 0) rotate(180deg) scale(-1);
}

span.caret.advanced-settings-toggle-icon.is-open {
    transform: translate3d(0, -50%, 0) rotate(180deg) scale(1);
}

.advanced-settings-toggle {
    user-select: none;
    font-weight: bold;
    cursor: pointer;
    padding: 8px 8px 8px 24px;
}

/* Advanced settings */
.advanced-settings.is-open {
    max-height: unset;
    opacity: 1;
    transition: max-height 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms linear;
}

.advanced-settings {
    max-height: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    transition: max-height 300ms cubic-bezier(0, 0, 0.2, 1), opacity 200ms linear;
}


/* Headway */
.headway-widget {
    width: 3rem;
    height: 3rem;
}

.headway-widget:hover {
    color: #333333;
}

.headway-icon {
    position: absolute;
    z-index: 0;
    top: 54%;
    left: 54%;
    font-size: 20px;
    transform: translate(-50%, -50%);
}

#HW_frame_cont {
    z-index: 10000 !important;
    transform: translateY(18px);
}

.askcody-logo-primary {
    max-width: 230px;
    margin: 0 auto;
}

.svg-img {
    width: 100%;
    height: auto;
}

/* Utility classes */
.display-inline-block {
    display: inline-block;
}

.display-none {
    display: none;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.negative-bottom-margin {
    margin-bottom: -25px;
}

.no-right-margin {
    margin-right: 0;
}

.position-relative {
    position: relative;
}

/* Specific elements */
#extraTableButtons {
    display: inline-block;
    margin-left: 1rem;
}

#extraTableButtons .checkbox label {
    display: inline-flex;
    align-items: center;
}

#extraTableButtons .checkbox label input {
    margin: 0 8px
}

@import '../base';

html, body {
    height: 100%;
    font-size: 16px;
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400;
}

body {
    align-items: center;
    background: linear-gradient(0deg,#0054ff,#00287a);
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100%;
    color: #001b52;

    &::after {
        background-image: url(./../../images/askcody-graphic-element.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 25% 50%;
        content: "";
        display: inline-block;
        height: 100vh;
        position: absolute;
        width: 100%;
        z-index: -1;
    }
}

.container {
    position: relative;
    background-color: #f7f7f7;
    max-width: 336px;
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .25rem .25rem .4rem .4rem;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 14%), 0 2px 2px 0 rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
}

/* Login */
form {
    max-width: 320px;
    margin: 0 auto;
    padding-top: 1rem;
}

.form-horizontal .form-group {
    margin: 1rem 0;
}

.help-block {
    color: #001b52;
}

form .btn,
form .btn:active,
form .btn:focus,
form .btn:hover,
form .btn:visited {
    font-size: .833rem;
    font-weight: bold;
}

.form-group {
    margin-bottom: 1rem;

    label {
        margin: 0;
        font-size: 13px;
    }
}

.sign-in-caption {
    font-size: 0.833rem;
    text-align: center;

    p {
        margin-bottom: 0.5rem;
    }
}

.or-separator {
    display: flex;
    justify-content: center;
    height: 1px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: #c5c5c5;

    p {
        margin-bottom: 0;
        font-size: 0.833rem;
        background-color: #f7f7f7;
        display: inline-block;
        margin-top: -1rem;
        color: #9c9c9c;
        padding: 0.5rem;
    }
}

#login-links {
    text-align: center;
    line-height: 1;
    font-size: .833rem;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0;
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-evenly;
}

#login-links a,
#login-links a:hover,
#login-links a:active {
    color: dimgrey;
}

#signup-links {
    font-size: .833rem;
    text-align: center;
    line-height: 1;
    border-radius: 0 0 .25rem .25rem;
    position: relative;
}

#signup-links a,
#signup-links a:hover,
#signup-links a:active {
    color: #fff;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0px);
    display: none;
}

.alert {
    max-width: 320px;
    margin: 0 auto 1.5rem;
    color: #001b52;
    background-color: #fff !important;
    border-color: #9c9c9c;
}

.alert-dismissable .close {
    color: #001b52;
    opacity: .75;
}

.alert-danger {
    border-color: #b22343;
    color: #b22343;
}

.alert-danger.alert-dismissable .close {
    color: #b22343;
}

.region-select {
    text-align: center;
    margin: 0.5rem 0 2rem;

    .btn-default {
        box-shadow: none;
        border-color: #00287a;
        color: #00287a;
        background-color: #fff;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        min-width: 3.6rem;
    }

    .btn-default:hover {
        background-color: rgba(0,40,122,.2);
    }

    .btn-primary[disabled] {
        background-color: rgba(0,40,122,.1);
        opacity: 1;
        color: #00287a;
        border-color: #00287a;
        font-weight: 800;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        min-width: 3.6rem;
    }

    .btn-primary[disabled]:hover {
        background-color: rgba(0,40,122,.1) !important;
    }

    p {
        margin: 0 0 0.5rem;
        font-size: 0.833rem;
    }

    .btn-sm:focus, .region-select .btn-sm:active {
        background-color: rgba(0,40,122,.2) !important;
        border-color: #001b52;
    }
}

.btn-sm, .btn-group-sm > .btn {
    padding: 0.5rem 1rem;
    font-size: 12px;
    line-height: 1.5;
    border-radius: .25rem;
}

.btn {
    border-radius: 0.25rem;
    min-width: 4.2rem;
    text-transform: uppercase;
    font-size: 0.833rem;
    padding: 0 1rem;
    min-height: 38px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    transition: background-color .2s ease;
}

.btn-block {
    border: none;
    min-height: 2.5rem;
    line-height: 2.5rem;
}

.btn:active, .btn:focus, .btn:hover {
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary, .btn-success {
    background-color: #00287a;
}

.btn-sso {

    img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
        margin-top: -4px;
    }
}

.btn-login {
    border: 1px solid #001b52;
    background-color: #fff;
    height: 38px;

    &:hover {
        background-color: rgba(0, 40, 122, 0.2);
    }
}

.btn-success:hover, .btn-success:active, .btn-success:focus, .btn-success:focus:active, .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:focus:active {
    background-color: #001e5b !important;
}

.form-control {
    border-radius: 0.25rem;
    border: 1px solid #e3e3e3;
    height: 2.5rem;
    color: #000;
    font-size: 1rem;
    box-shadow: none;
    background-color: #fff;

    &:focus {
        border-color: #00287a;
        outline: none;
        box-shadow: none;
    }
}

/* SVG icons */
.svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: #2e3e45;
    fill: #2e3e45;
}

.svg-centered {
    text-align: center;
}

.askcody-logo-primary {
    max-width: 168px;
    margin: 0 auto 2rem;
}

.svg-img {
    width: 100%;
    height: auto;
}
